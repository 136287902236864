import React, {useCallback, useEffect, useState} from "react";
import Loading from "../../components/Loading";
import {Button, Table} from "reactstrap";
import dayjs from 'dayjs'
import PaginationControls from "../photographers/tables/PaginationControls";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import 'react-tabs/style/react-tabs.css';
import {useDispatch} from "react-redux";
import {displayAlert} from "../../store/AlertSlice";
import {determineIfTokenError} from "../../store/AuthThunks";
import {callRemote, GET} from "../../service/RemoteService";
import GrabModal from "./modals/GrabModal";


export const ProfileComponent = () => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const [jobs, setjobs] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [modalState, setModalState] = useState({ isOpen: false });

    const getJobs = useCallback(async () => {
        try {
            const myJobsResponse = await callRemote(getAccessTokenSilently, GET, "/jobs/my-jobs");
            if (myJobsResponse.total > 0) {
                dispatch(displayAlert(false, "You already have a job in progress!"));
                return;
            }
        } catch (e) {
            dispatch(determineIfTokenError(e));
        }

        callRemote(getAccessTokenSilently, GET, "/jobs/?pageNumber=" + currentPage)
            .then((jobsResponse) => {
                setjobs(jobsResponse);
            }).catch(e => {
                dispatch(determineIfTokenError(e));
            });
    }, [currentPage, dispatch, getAccessTokenSilently])

    useEffect(() => {
        getJobs();
    }, [getJobs, currentPage]);

    const openModal = async (job) => {
        setModalState({ isOpen: true, job: job });
    }
    const closeModal = () => {
        setModalState({ isOpen: false });
    }

    return (
        <div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Photos Submitted Date</th>
                        <th>Vehicle</th>
                        <th>VIN</th>
                        <th>Type</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>{jobs != null && jobs.jobs.map(job => (
                    <tr key={job.id}>
                        <td>{dayjs(job.photosSubmittedDate).format('MM/DD/YYYY h:mm a')}</td>
                        <td>{job.car}</td>
                        <td>{job.vin}</td>
                        <td>{job.jobType}</td>
                        <td>{<Button onClick={() => openModal(job)} color="primary">Grab</Button>}</td>
                    </tr>
                ))}
                </tbody>
            </Table>

            <PaginationControls
              elements={jobs}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />

            <GrabModal
                isOpen={modalState.isOpen}
                getJobs={() => getJobs(currentPage)}
                closeModal={closeModal}
                setModalState={setModalState}
                job={modalState.job}
            />
        </div>
    );
};

export default withAuthenticationRequired(ProfileComponent, {
    onRedirecting: () => <Loading />,
});
