import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Col, Container, Row, UncontrolledTooltip} from "reactstrap";
import {useNavigate, useParams} from "react-router-dom";
import {callRemote, GET, POST} from "../../service/RemoteService";
import {determineIfTokenError} from "../../store/AuthThunks";
import {useDispatch} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {displayAlert} from "../../store/AlertSlice";
import {useAuth0} from "@auth0/auth0-react";
import ListingDetailImagesDisplay from "../../components/gallery/ListingDetailImagesDisplay";
import {Box, Grid} from "@mui/material";

export const photoCategoriesInit = {
  exterior:[],
  interior:[],
  mechanical:[],
  docs:[],
  condition:[],
  other:[]
}

export const featurePhotosInit = {
  thumbnail:null,
  overview:null,
  interiorExterior:null,
  engine:null,
  interiorFeature:null
}

const PhotoUploads = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const {car} = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const imageInputRefs = useRef([]);
  imageInputRefs.current = imageInputRefs.current.slice(0, Object.getOwnPropertyNames(photoCategoriesInit).length);

  const featureImageInputRefs = useRef([]);
  featureImageInputRefs.current = featureImageInputRefs.current.slice(0, Object.getOwnPropertyNames(featurePhotosInit).length);

  const imageurl = '/assets/placeholder.jpg';
  const [photosDisplay, setPhotosDisplay] = useState(photoCategoriesInit);
  const [photosFiles, setPhotosFiles] = useState(photoCategoriesInit);
  const [featurePhotosDisplay, setFeaturePhotosDisplay] = useState(featurePhotosInit);
  const [featurePhotosFiles, setFeaturePhotosFiles] = useState(featurePhotosInit);
  const [existingImages, setExistingImages] = useState([]);

  const odometerInputRef = useRef(null);
  const vinPlateInputRef = useRef(null);
  const [odometerUploadUrl, setOdometerUploadUrl] = useState(imageurl);
  const [vinPlateUploadUrl, setVinPlateUploadUrl] = useState(imageurl);
  const [odometerMultipartFile, setOdometerMultipartFile] = useState(null);
  const [vinPlateMultipartFile, setVinPlateMultipartFile] = useState(null);

  const getPhotos = useCallback(async () => {
    callRemote(getAccessTokenSilently, GET, "/jobs/" + id)
      .then((jobResponse) => {
        setExistingImages(jobResponse.contents);
        console.log(jobResponse.contents)
      }).catch(e => {
      dispatch(determineIfTokenError(e));
    });
  }, [dispatch, getAccessTokenSilently, id]);

  useEffect(() => {
    getPhotos();
  }, [id, getPhotos]);

  const handleMultipleImagesUpload = (event, category) => {
    if (event.target.files != null && event.target.files.length > 0) {
      const files = event.target.files;
      const imageUrls = [];
      const imageFiles = [];

      for (let i = 0; i < files.length; i++) {
        if (imageUrls.findIndex(url => url === URL.createObjectURL(files[i])) === -1) {
          imageUrls.push(URL.createObjectURL(files[i]));
        }
      }
      for (let i = 0; i < files.length; i++) {
        imageFiles.push(files[i]);
      }

      let newPhotos = {...photosDisplay}
      newPhotos[category] = [...photosDisplay[category]].concat(imageUrls);
      setPhotosDisplay(newPhotos);

      let newFiles = {...photosFiles}
      newFiles[category] = [...photosFiles[category]].concat(imageFiles);
      setPhotosFiles(newFiles);
    }
  }

  const handleSingleFeaturedImageUpload = (event, category) => {
    if (event.target.files != null && event.target.files.length > 0) {
      const file = event.target.files.item(0);
      const imageUrl = URL.createObjectURL(file);

      let newDisplay = {...featurePhotosDisplay}
      newDisplay[category] = imageUrl;
      setFeaturePhotosDisplay(newDisplay)

      let newFiles = {...featurePhotosFiles}
      newFiles[category] = file;
      setFeaturePhotosFiles(newFiles)
    }
  }

  const handleSingleDocumentImageUpload = (event, type) => {
    if (event.target.files != null && event.target.files.length > 0) {
      const file = event.target.files.item(0);
      const imageUrl = URL.createObjectURL(file);

      if (type.includes('odometer')) {
        setOdometerUploadUrl(imageUrl)
        setOdometerMultipartFile(file)
      } else {
        setVinPlateUploadUrl(imageUrl)
        setVinPlateMultipartFile(file)
      }
    }
  }

  const removeImage = (category, index) => {
    let newPhotos = {...photosDisplay}
    newPhotos[category].splice(index, 1)
    setPhotosDisplay(newPhotos);

    let newFiles = {...photosFiles}
    newFiles[category].splice(index, 1)
    setPhotosFiles(newFiles);
  }

  const removeFeatureImage = (feature) => {
    let newPhotos = {...featurePhotosDisplay}
    newPhotos[feature] = null;
    setFeaturePhotosDisplay(newPhotos);

    let newFiles = {...featurePhotosFiles}
    newFiles[feature] = null
    setFeaturePhotosFiles(newFiles);
  }

  const submitPhotos = async () => {
    const formData = new FormData();
    for (let category of Object.getOwnPropertyNames(photoCategoriesInit)) {
      photosFiles[category].forEach(element => {
        formData.append(category, element);
      });
    }

    for (let feature of Object.getOwnPropertyNames(featurePhotosInit)) {
      formData.append(feature, featurePhotosFiles[feature]);
      let existingFeatureImage = existingImages.filter(img => img.key.includes('/' + feature + '/'))
      if (existingFeatureImage.length > 0 && featurePhotosFiles[feature] !== null) {
        formData.append('imagesToRemove', existingFeatureImage[0].key);
      }
    }

    callRemote(getAccessTokenSilently, POST, `/jobs/${id}/images-upload`, formData)
      .then(async () => {
        await resetToInit();
        await getPhotos();
        dispatch(displayAlert(true, "photos uploaded successfully"));
      }).catch(e => {
        dispatch(determineIfTokenError(e));
      }).finally(() => {
      })
  }

  const submitDocumentPhotos = () => {
    const formData = new FormData();
    formData.append('odometer', odometerMultipartFile)
    formData.append('vinPlate', vinPlateMultipartFile)

    callRemote(getAccessTokenSilently, POST, `/jobs/${id}/image-document-upload`, formData)
      .then(voidResp => {
        dispatch(displayAlert(true, "photo documents uploaded successfully"));
        resetDocumentsToInit();
      })
      .catch(e => {
        dispatch(determineIfTokenError(e));
      }).finally(() => {});
  }

  const resetToInit = async () => {
    setPhotosDisplay(photoCategoriesInit);
    setPhotosFiles(photoCategoriesInit);
    setFeaturePhotosDisplay(featurePhotosInit);
    setFeaturePhotosFiles(featurePhotosInit);
    await setExistingImages([]);
  }

  const resetDocumentsToInit = () => {
    setVinPlateMultipartFile(null)
    setOdometerMultipartFile(null)
    setVinPlateUploadUrl(imageurl)
    setOdometerUploadUrl(imageurl)
  }

  return (
    <Container fluid>
      <Button color="link" onClick={() => navigate(-1)} style={{display:'inline'}}>
        <FontAwesomeIcon icon="arrow-left" /> Back
      </Button>
      <div style={{display:'flex', justifyContent:'center', marginBottom:'50px'}}>
        <h1 style={{display:'inline', fontSize:'xx-large'}}>{'Upload Photos for the ' + car}</h1>
      </div>

      <Row>
        { Object.getOwnPropertyNames(photoCategoriesInit).map((category, index) => (
          <Col key={category} style={{marginBottom:'35px'}} xl={'4'} lg={'4'} md="6" sm="12" xs={'12'}>
            <h2 style={{textAlign:'center', fontSize:'x-large'}}>{category}</h2>
            <Row>
              <Col xs={photosDisplay[category].length === 0 ? '12' : '4'}>
                <input type={'file'} accept={'image/*'} hidden={true} multiple ref={el => imageInputRefs.current[index] = el}
                    onChange={(e) => handleMultipleImagesUpload(e, category)} />
                <img src={imageurl} alt={'Upload'} id={`uploader${index}`}
                    style={{cursor: "pointer", height: photosDisplay[category].length === 0 ? "200px" : "60px", width: "100%", marginBottom:'10px'}}
                    onClick={() => imageInputRefs?.current[index]?.click()}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`uploader${index}`}
                >
                  Click to upload {category} photos
                </UncontrolledTooltip>
              </Col>

              { photosDisplay[category].map((img, index2) => (
                <Col xs={photosDisplay[category].length === 0 ? '12' : '4'} key={img}>
                  <img key={img} src={img} alt={'Uploaded'} id={`upload${index}${index2}`}
                     onClick={() => removeImage(category, index2, photosDisplay, photosFiles)}
                    style={{cursor: "pointer", height: "60px", width: "100%", marginBottom:'10px'}}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`upload${index}${index2}`}
                    style={{backgroundColor:'red'}}
                  >
                    Click to Delete
                  </UncontrolledTooltip>
                </Col>
              ))}
            </Row>
          </Col>
        ))}
      </Row>

      <div style={{height:'5px', width:'100%', backgroundColor:'grey', marginBottom:'35px'}} />

      <Row>
        { Object.getOwnPropertyNames(featurePhotosInit).map((feature, index) => (
          <Col key={feature} style={{marginBottom:'35px'}} xl={'4'} lg={'4'} md="6" sm="12" xs={'12'}>
            <h2 style={{textAlign:'center', fontSize:'x-large'}}>{feature}</h2>
            <Row>
              <Col xs={!featurePhotosDisplay[feature] ? '12' : '6'}>
                <input type={'file'} accept={'image/*'} hidden={true} ref={el => featureImageInputRefs.current[index] = el}
                       onChange={(e) => handleSingleFeaturedImageUpload(e, feature)} />
                <img src={imageurl} alt={'Upload'} id={`featureUploader${index}`}
                     style={{cursor: "pointer", height: !featurePhotosDisplay[feature] ? "200px" : "90px", width: "100%", marginBottom:'10px'}}
                     onClick={() => featureImageInputRefs?.current[index]?.click()}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`featureUploader${index}`}
                >
                  Click to upload {feature} photo
                </UncontrolledTooltip>
              </Col>

              {featurePhotosDisplay[feature] &&
                <Col xs={'6'}>
                  <img src={featurePhotosDisplay[feature]} alt={'Uploaded'} id={`featureUpload${index}`}
                       onClick={() => removeFeatureImage(feature)}
                       style={{cursor: "pointer", height: "90px", width: "100%", marginBottom: '10px'}}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`featureUpload${index}`}
                    style={{backgroundColor: 'red'}}
                  >
                    Click to Delete
                  </UncontrolledTooltip>
                </Col>
              }
            </Row>
          </Col>
        ))}
      </Row>

      <Button color="primary" onClick={submitPhotos}
              style={{width:'100%', marginBottom:'35px', padding:'10px 0'}}>
        Submit Photos
      </Button>

      <div style={{height:'5px', width:'100%', backgroundColor:'grey', marginBottom:'35px'}} />

      <Box sx={{mb:5}}>
        <ListingDetailImagesDisplay
          contents={existingImages}
          id={id}
          tabslist={photoCategoriesInit}
          filterOnGallery={true}
        />
      </Box>
      <Box sx={{mb:5}}>
        <ListingDetailImagesDisplay
          contents={existingImages}
          id={id}
          tabslist={featurePhotosInit}
          filterOnGallery={false}
        />
      </Box>

      <div style={{height:'5px', width:'100%', backgroundColor:'grey', marginBottom:'35px'}} />

      <div style={{display:'flex', justifyContent:'center', marginBottom:'50px'}}>
        <h3 style={{display:'inline', fontSize:'x-large'}}>{'Upload Photos of VIN and Odometer'}</h3>
      </div>
      <Grid container spacing={2} sx={{mb:2}}>
        <Grid item sm={6} xs={12}>
          <h2 style={{textAlign:'center', fontSize:'large'}}>{'Odometer'}</h2>
          <input type={'file'} accept={'image/*'} hidden={true} ref={odometerInputRef}
                 onChange={(e) => handleSingleDocumentImageUpload(e,'odometer')} />
          <img src={odometerUploadUrl} alt={'Upload'}
               style={{cursor: "pointer", maxHeight: "300px", width: "100%"}}
               onClick={() => odometerInputRef?.current?.click()}/>
        </Grid>
        <Grid item sm={6} xs={12}>
          <h2 style={{textAlign:'center', fontSize:'large'}}>{'VIN Plate'}</h2>
          <input type={'file'} accept={'image/*'} hidden={true} ref={vinPlateInputRef}
                 onChange={(e) => handleSingleDocumentImageUpload(e,'vin')} />
          <img src={vinPlateUploadUrl} alt={'Upload'}
               style={{cursor: "pointer", maxHeight: "300px", width: "100%"}}
               onClick={() => vinPlateInputRef?.current?.click()}/>
        </Grid>
      </Grid>
      <Button color="primary" onClick={submitDocumentPhotos}
              style={{width:'100%', marginBottom:'35px', padding:'10px 0'}}>
        Submit Photos
      </Button>
    </Container>
  );
}

export default PhotoUploads;