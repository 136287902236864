import React, {useCallback, useEffect, useState} from "react";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useAuth0} from "@auth0/auth0-react";
import {callRemote, POST} from "../../../service/RemoteService";
import {shootsActions} from "../../../store/storeIndex";
import {displayAlert} from "../../../store/AlertSlice";
import {determineIfTokenError} from "../../../store/AuthThunks";
import {useDispatch} from "react-redux";
import {Alert, AlertTitle} from "@mui/material";

export const JobSubmitModal = (props) => {
    let {job} = props;
    let contents = job?.contents;
    let dispatch = useDispatch();
    const { getAccessTokenSilently, user } = useAuth0();

    const roles = user != null && user["http://exoticcartrader.com/role"];
    const isEditor = roles.includes('Editor');
    const isBlogger = roles.includes('Blogger');
    const [eachEntry, setEachEntry] = useState({
        overview: '',
        interiorExterior: '',
        engine: ''
    });
    const [photosValid, setPhotosValid] = useState(null);

    const submit = async () => {
        if ((isBlogger && !validate()) || (isEditor && !photosValid)) {
            return;
        }
        
        callRemote(getAccessTokenSilently, POST, "/jobs/" + props.job.id + "/submit", { ...eachEntry })
            .then(() => {
                dispatch(shootsActions.incrementActionsTaken());
                dispatch(displayAlert(true, "Job Submitted"));
            }).catch(e => {
                dispatch(determineIfTokenError(e));
            }).finally(() => {
                props.getJobs();
                props.closeModal();
            })
    }


    const [validation, setValidation] = useState({
        overview: true,
        interiorExterior: true,
        engine: true
    });
    const handleInputChange = e => {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
        setValidation({ ...validation, [e.target.name]: e.target.value !== '' });
    };
    const validate = () => {
        const newValidation = {
            overview: eachEntry.overview !== '',
            interiorExterior: eachEntry.interiorExterior !== '',
            engine: eachEntry.engine !== ''
        };
        setValidation(newValidation);
        return Object.values(newValidation).every(Boolean);
    }

    const checkPhotosValid = useCallback(() => {
        let valid = contents?.length > 0;
        valid = valid && contents?.filter(img => img.type === 'THUMBNAIL').length > 0;
        valid = valid && contents?.filter(img => img.type === 'INTERIOR_EXTERIOR').length > 0;
        valid = valid && contents?.filter(img => img.type === 'OVERVIEW').length > 0;
        valid = valid && contents?.filter(img => img.type === 'ENGINE').length > 0;
        valid = valid && contents?.filter(img => img.key.includes('/interior/'));
        valid = valid && contents?.filter(img => img.type === 'GALLERY').length > 4;
        valid = valid && job.odometerImageUploaded && job.vinPlateImageUploaded;
        setPhotosValid(valid)
    }, [job, setPhotosValid, contents])


    useEffect(() => {
        checkPhotosValid()
    }, [checkPhotosValid, contents])

    return (
        <Modal isOpen={props.isOpen} toggle={props.closeModal}>
            <ModalHeader toggle={props.closeModal}>Mark As Submitted</ModalHeader>
            <ModalBody>
                {isEditor ? (<h4>Do not submit unless you have uploaded the photos!</h4>) : null}
                {isBlogger ? (
                    <Form>
                        <FormGroup>
                            <Label for="overview">Overview</Label>
                            <Input type="textarea" name="overview" id="overview" onChange={handleInputChange} invalid={!validation.overview} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="interiorExterior">Interior/Exterior</Label>
                            <Input type="textarea" name="interiorExterior" id="interiorExterior" onChange={handleInputChange} invalid={!validation.interiorExterior} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="engine">Engine</Label>
                            <Input type="textarea" name="engine" id="engine" onChange={handleInputChange} invalid={!validation.engine} />
                        </FormGroup>
                    </Form>) : null}

                { (isEditor && !photosValid) &&
                    <Alert severity={'error'}>
                        <AlertTitle><b>All required Photos must be uploaded before the job can be completed.</b></AlertTitle>
                        Photos Missing:
                        <ul>
                            { contents?.filter(img => img.type === 'THUMBNAIL').length === 0 && <li>Thumbnail</li>}
                            { contents?.filter(img => img.type === 'INTERIOR_EXTERIOR').length === 0 && <li>Interior/Exterior</li>}
                            { contents?.filter(img => !img.key.includes('/interior/')).length === 0 && <li>Interior</li>}
                            { contents?.filter(img => img.type === 'OVERVIEW').length === 0 && <li>Overview</li>}
                            { contents?.filter(img => img.type === 'ENGINE').length === 0 && <li>Engine</li>}
                            { contents?.filter(img => img.type === 'GALLERY').length < 5 && <li>Gallery</li>}
                            { !job?.odometerImageUploaded && <li>Odometer</li>}
                            { !job?.vinPlateImageUploaded && <li>VIN Plate</li>}
                        </ul>
                    </Alert>
                }
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => submit()} color="primary"
                        disabled={(isBlogger && (eachEntry.engine?.length === 0 ||
                          eachEntry.interiorExterior?.length === 0 || eachEntry.overview?.length === 0)) ||
                        (isEditor && !photosValid)}>
                    Submit
                </Button>
                <Button onClick={() => props.closeModal()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default JobSubmitModal;
