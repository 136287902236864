import React, {useState} from "react";
import {FreeMode, Navigation, Thumbs, Zoom} from "swiper";
import {Box} from "@mui/material";
import {SwiperSlide, Swiper} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "./swiper-styles.css";
import DeleteImageDialog from "./DeleteImageDialog";
import {UncontrolledTooltip} from "reactstrap";


const ListingImageGallery = (props) => {
  let contents = props.contents;
  let onSubmit = props.onSubmit;

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [pathToDelete, setPathToDelete] = useState(null)

  const onDeleteSubmit = () => {
    onSubmit(pathToDelete)
    setDeleteDialogOpen(false)
  }

  const onImageClick = (path) => {
    setPathToDelete(path);
    setDeleteDialogOpen(true)
  }

  return (
    <>
      <Swiper
        slidesPerView={contents.length > 2 ? 2 : contents.length}
        style={{
          height:contents.length > 2 ? '35vh' : '55vh',
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        navigation={true}
        spaceBetween={7}
        zoom={true}
        loop={true}
        modules={[Zoom, FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        { contents.map((content, index) => (
          <SwiperSlide style={{height:contents.length > 2 ? '35vh' : '55vh'}} key={content.key}>
            <div className="swiper-zoom-container" style={{width:'100%'}}>
              <img id={'listingImage' + index} style={{cursor:'pointer'}} alt={'main gallery'}
                   src={`${process.env.REACT_APP_CONTENT_BASE}/${content.key}`}
                   onClick={() => onImageClick(content.key)}
              />
              <UncontrolledTooltip
                placement="top"
                target={`listingImage${index}`}
                style={{backgroundColor:'red'}}
              >
                Click to remove photo
              </UncontrolledTooltip>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Box sx={{height:'5px'}}></Box>
      <Swiper
        style={{
          "height": "40px", cursor:'pointer'
        }}
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={7}
        slidesPerView={contents.length}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="swiper-thumb"
      >
        { contents.map(content => (
          <SwiperSlide key={'picker' + content.key}>
            <img src={`${process.env.REACT_APP_CONTENT_BASE}/${content.key}`} alt={'gallery toggle slider'} />
          </SwiperSlide>
        ))}
      </Swiper>

      <DeleteImageDialog dialogOpen={deleteDialogOpen} setDialogOpen={setDeleteDialogOpen} onSubmit={onDeleteSubmit} />
    </>
  );
}

export default ListingImageGallery;