import axios from "axios";
import {isExpired} from "react-jwt";

export const callRemote = async (getAccessTokenSilently, type, path, data) => {
    type = type.toLowerCase();

    if (isTokenNotExpired()) {
        return doCallRemote(type, path, data);
    }

    await getAccessTokenSilently({audience: "https://team.exoticcartrader.com/"})
        .then(auth => {
            console.log('in the then')
            sessionStorage.setItem('token', auth);
        });

    return doCallRemote(type, path, data);
}

const isTokenNotExpired = () => {
    let token = sessionStorage.getItem("token")
    return token && !isExpired(token);
}

const doCallRemote = (type, path, data) => {
    return axios({
        method:type,
        url:process.env.REACT_APP_API_BASE_URL + path,
        data:data,
        headers:{ Authorization: "Bearer " + sessionStorage.getItem('token') }
    })
      .then(handleApiError)
      .then(async (response) => await response.data)
      .catch(e => {throw e});
}

async function handleApiError(response) {
    if (response.status !== 200) {
        if (response.status === 404) {
            throw Error("There was an unexpected network failure");
        }
        let errorResponse = await response.data;
        throw Error(errorResponse.message);
    }
    return response;
}

export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';
export const DELETE = 'delete';