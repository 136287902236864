import React, {useCallback, useEffect, useState} from "react";
import {Table} from "reactstrap";
import dayjs from 'dayjs'
import {useAuth0} from "@auth0/auth0-react";
import {callRemote, GET} from "../../../service/RemoteService";
import {determineIfTokenError} from "../../../store/AuthThunks";
import {useDispatch} from "react-redux";
import PaginationControls from "../../photographers/tables/PaginationControls";
import {useNavigate} from "react-router-dom";
import JobSubmitModal from "../modals/JobSubmitModal";
import {Button} from "@mui/material";

export const MyjobsTable = (props) => {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const { getAccessTokenSilently, user } = useAuth0();
    const roles = user != null && user["http://exoticcartrader.com/role"];
    const isEditor = roles.includes('Editor');
    const isBlogger = roles.includes('Blogger');

    const [jobs, setjobs] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [editDescriptionsModal, setEditDescriptionsModal] = useState({ isOpen: false });

    const getJobs = useCallback(async () => {
        callRemote(getAccessTokenSilently, GET, "/jobs/my-submitted-jobs?pageNumber=" + currentPage)
            .then((jobsResponse) => {
                setjobs(jobsResponse);
            }).catch(e => {
                dispatch(determineIfTokenError(e));
            });
    }, [dispatch, getAccessTokenSilently, currentPage]);

    useEffect(() => {
        getJobs();
    }, [getJobs]);

    const openEditDescriptionsModal = async (job) => {
        setEditDescriptionsModal({ isOpen: true, job: job });
    }

    const closeEditDescriptionsModal = () => {
        setEditDescriptionsModal({ isOpen: false });
    }

    return (
        <div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Submitted Date</th>
                        <th>Car</th>
                        <th>VIN</th>
                        <th>Type</th>
                        <th>Modify Action</th>
                    </tr>
                </thead>
                <tbody>{jobs != null && jobs.jobs.map(job => (
                    <tr key={job.id}>
                        <td>{dayjs(job.submittedDate).format('MM/DD/YYYY h:mm a')}</td>
                        <td>{job.car}</td>
                        <td>{job.vin}</td>
                        <td>{job.jobType}</td>
                        <td>
                            {isEditor &&
                                <Button variant={'text'} onClick={() => navigate(`/upload-photos/${job.id}/${job.car}`)}>
                                    Modify S3 photos
                                </Button>
                            }
                            |
                            { isBlogger &&
                              <Button variant={'text'} onClick={() => openEditDescriptionsModal(job)}>
                                  Edit Descriptions
                              </Button>
                            }
                        </td>
                    </tr>
                ))
                }
                </tbody>
            </Table>
            <PaginationControls elements={jobs} currentPage={currentPage} setCurrentPage={setCurrentPage} />

            <JobSubmitModal
              photographerId={user.sub}
              job={editDescriptionsModal.job}
              isOpen={editDescriptionsModal.isOpen}
              getJobs={getJobs}
              closeModal={closeEditDescriptionsModal}
              setModalState={editDescriptionsModal}
            />
        </div>
    );
};

export default MyjobsTable;
