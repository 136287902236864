import {Box, Chip, Tab, Tabs, useMediaQuery} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import ListingImageGallery from "./ListingImageGallery";
import {callRemote, DELETE} from "../../service/RemoteService";
import {determineIfTokenError} from "../../store/AuthThunks";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch} from "react-redux";


const ListingDetailImagesDisplay = (props) => {
  const {getAccessTokenSilently} = useAuth0();
  const dispatch = useDispatch();
  let id = props.id;
  let tabslist = props.tabslist;
  let filterOnGallery = props.filterOnGallery;
  let smallWidthContainer = props.smallWidthContainer;
  const isWindowLarge = useMediaQuery(`(min-width:${smallWidthContainer ? '1400' : '800'}px)`);
  const [tabValue, setTabValue] = useState('all');
  const [totalImages, setTotalImages] = useState(0);
  const [galleryDisplayList, setGalleryDisplayList] = useState(null)
  const [contents, setContents] = useState(props.contents)

  useEffect(() => {
    props.contents && setContents(props.contents)
  }, [props.contents, setContents]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getListOfGalleryImagesByTab = useCallback(() => {
    let images = filterOnGallery ? contents.filter(image => image.type === 'GALLERY') : contents.filter(image => image.type !== 'GALLERY');
    setTotalImages(images.length)
    if (tabValue === 'all') {
      return images;
    }
    let tabSelected = tabValue;
    if (tabValue.includes('mech')) {
      tabSelected = 'mechanical';
    }
    if (tabValue.includes('mech')) {
      tabSelected = 'mechanical';
    } else if (tabSelected.includes('interiorFeature')) {
      tabSelected = 'interior';
    }
    return images.filter(img => img.key.includes("/" + tabSelected + "/"));
  }, [contents, setTotalImages, tabValue, filterOnGallery]);

  useEffect(() => {
    contents && setGalleryDisplayList(getListOfGalleryImagesByTab());
  }, [contents, tabValue, getListOfGalleryImagesByTab]);

  const getTabsListByDisplay = () => {
    let tabs = Object.getOwnPropertyNames(tabslist);
    if (!isWindowLarge) {
      tabs[tabs.indexOf('mechanical')] = 'mech';
    }
    return isWindowLarge ? tabs : tabs.slice(0,3);
  }

  const removeImage = (path) => {
    callRemote(getAccessTokenSilently, DELETE, "/jobs/" + id + "/image?path=" + path)
      .then((removeResp) => {
        setContents(removeResp)
      }).catch(e => {
        dispatch(determineIfTokenError(e));
    });
  }

  return (
    <section style={{marginTop:'20px', display:'block'}}>
      <Box sx={{ width: '100%', marginBottom:'2px'}}>
        {/*TODO: need to add this indicator that gallery is double click to zoom. Make it look nice*/}
        {/*<Stack direction={'row'} sx={{float:'right'}} spacing={1}>
          <InfoOutlined fontSize={'small'} sx={{color:ROYAL_BLUE, pb:0.5}} />
          <Typography variant="caption" component="div" >
            Double click to zoom
          </Typography>
        </Stack>*/}

        <Tabs
          /*orientation={isWindowLarge ? 'horizontal' : 'vertical'}*/
          value={tabValue}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          aria-label="secondary tabs example"
        >
          <Tab value={"all"} label={
            <Box sx={{display:"inline"}}>
              <span style={{marginRight:'5px'}}>all</span>
              <Chip sx={{cursor:'pointer'}} label={totalImages} />
            </Box>
          }/>
          { getTabsListByDisplay().map(category => (
            <Tab key={category} value={category} label={category} />
          ))}
        </Tabs>
        { !isWindowLarge &&
        <Tabs
          /*orientation={isWindowLarge ? 'horizontal' : 'vertical'}*/
          value={null}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          aria-label="secondary tabs example"
        >
          { Object.getOwnPropertyNames(tabslist)
                .slice(3, Object.getOwnPropertyNames(tabslist).length + 1).map(category => (
            <Tab key={category} value={category} label={category} />
          ))}
        </Tabs>
        }
      </Box>

      { galleryDisplayList &&
        <ListingImageGallery contents={galleryDisplayList} onSubmit={removeImage}/>
      }

    </section>
  );
}

export default ListingDetailImagesDisplay;