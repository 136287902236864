import React, { useState, useCallback } from "react";
import { Table } from "reactstrap";
import states from "../states.json";
import { useDispatch } from "react-redux";
import { determineIfTokenError } from "../../store/AuthThunks";
import { callRemote } from "../../service/RemoteService";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";

export const ProfileComponent = () => {
  let dispatch = useDispatch();
  const { loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();

  const [photographers, setPhotographers] = useState();

  const getPhotographers = useCallback((state) => {
    callRemote(getAccessTokenSilently, 'get', "/photographers/?state=" + state)
      .then(resp => {
        setPhotographers(resp);
      }).catch(e => {
        dispatch(determineIfTokenError(e, loginWithRedirect, logout));
      });
  }, [dispatch, setPhotographers, loginWithRedirect, logout, getAccessTokenSilently]);

  const setNewValue = (event) => {
    getPhotographers(event.target.value);
  }

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  return (
    <div>
      <div style={{ display: "flex" }}>
        <h2>Photographer Search</h2>
        <select onChange={setNewValue} style={{ margin: "10px" }} defaultValue="Select State">
          <option key="default" value="default" hidden>
            Select a State
          </option>
          {states.map(item => (
            <option key={item.abbreviation} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
          </tr>
        </thead>
        {photographers && photographers.map(photographer => (<tbody>
          <tr key={photographer.id}>
            <td>{photographer.name}</td>
            <td>{photographer.email}</td>
            <td>{formatPhoneNumber(photographer.phoneNumber)}</td>
            <td>{photographer.city}</td>
            <td>{photographer.state}</td>
            <td>{photographer.zip}</td>
          </tr>

        </tbody>
        ))}
      </Table>

    </div>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
