import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";


const DeleteImageDialog = (props) => {
  let setDialogOpen = props.setDialogOpen;
  let dialogOpen = props.dialogOpen;
  let onSubmit = props.onSubmit;

  return (
    <>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="sm"
        open={dialogOpen}
      >
        <DialogTitle>
          {'Are you sure?'}
          <IconButton
            aria-label="close"
            onClick={() => setDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <>
          <DialogContent dividers>
            <Typography variant="body1" component="div" sx={{fontSize:'large'}}>
              This is permanent. Are you sure you want to remove this image from the listing?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => {
              setDialogOpen(false)
            }}>
              Cancel
            </Button>
            <Button type={'button'} size="large" color={"error"}
                    variant={"contained"}
                    onClick={onSubmit}>
              Remove
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  )
}

export default DeleteImageDialog;