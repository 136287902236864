import React, { useState, useEffect, useCallback } from "react";
import { Table } from "reactstrap";
import PaginationControls from "./PaginationControls";
import dayjs from 'dayjs'
import { formatCurrency } from "../../../utils/utils";
import { useAuth0 } from "@auth0/auth0-react";
import {callRemote, GET} from "../../../service/RemoteService";
import {determineIfTokenError} from "../../../store/AuthThunks";
import {useDispatch} from "react-redux";

export const CompletedTable = () => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const [currentPage, setCurrentPage] = useState(0);
    const [shoots, setShoots] = useState();

    const getShoots = useCallback(async () => {
        callRemote(getAccessTokenSilently, GET, "/shoots/?status=completed&pageNumber=" + currentPage)
        .then((shootsResponse) => {
            setShoots(shootsResponse);
        }).catch(e => {
            dispatch(determineIfTokenError(e));
        });
    }, [currentPage, dispatch, getAccessTokenSilently]);

    useEffect(() => {
        getShoots();
    }, [getShoots]);

    return (
        <div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Paid Date</th>
                        <th>Vehicle</th>
                        <th>VIN</th>
                        <th>Customer</th>
                        <th>Photographer</th>
                        <th>Paid Amount</th>
                    </tr>
                </thead>
                <tbody>{shoots != null && shoots.shoots.map(shoot => (
                    <tr key={shoot.id}>
                        <td>{dayjs(shoot.paidDate).format('MM/DD/YYYY h:mm a')}</td>
                        <td>{shoot.car}</td>
                        <td>{shoot.vin}</td>
                        <td>{shoot.customerName}</td>
                        <td>{shoot.photographerName}</td>
                        <td>{formatCurrency(shoot.paidAmount)}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <PaginationControls elements={shoots} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </div>
    );
};

export default CompletedTable;
