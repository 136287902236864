import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink, faPowerOff, faUser, faRedo, faCalendarDay, faBan, faCheck, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function initFontAwesome() {
  library.add(faLink);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faRedo);
  library.add(faCalendarDay);
  library.add(faBan);
  library.add(faCheck)
  library.add(faArrowLeft)
}

export default initFontAwesome;
