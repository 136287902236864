import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Loading from "../../components/Loading";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import MyShootsTable from "./tables/MyShootsTable";
import MyCompletedShoots from "./tables/MyCompletedShoots";
import { withRouter } from "../../utils/withRouter"
import {callRemote, GET} from "../../service/RemoteService";
import {determineIfTokenError} from "../../store/AuthThunks";
import {useDispatch} from "react-redux";

export const ProfileComponent = (props) => {
  let dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const checkStatus = async () => {
    callRemote(getAccessTokenSilently, GET, "/photographers/status")
    .then((statusRespose) => {
      if (!statusRespose.submittedApplication) {
        props.history('/photographers-apply');
      } else if (!statusRespose.agreementSigned) {
        props.history('/sign-agreement');
      } else if (!statusRespose.stripeSetup) {
        props.history('/stripe-setup');
      }
    }).catch(e => {
      dispatch(determineIfTokenError(e));
    });
  }
  checkStatus();
  return (
    <div>
      <Tabs>
        <TabList>
          <Tab>Active Shoots</Tab>
          <Tab>Submitted Shoots</Tab>
        </TabList>

        <TabPanel><MyShootsTable /></TabPanel>
        <TabPanel><MyCompletedShoots /></TabPanel>
      </Tabs>

    </div>
  );
};

export default withAuthenticationRequired(withRouter(ProfileComponent), {
  onRedirecting: () => <Loading />,
});
