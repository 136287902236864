import React, { useState, useEffect, useCallback } from "react";
import { Table } from "reactstrap";
import dayjs from 'dayjs'
import PaginationControls from "./PaginationControls";
import { useAuth0 } from "@auth0/auth0-react";
import { formatCurrency } from "../../../utils/utils";
import {callRemote, GET} from "../../../service/RemoteService";
import {determineIfTokenError} from "../../../store/AuthThunks";
import {useDispatch} from "react-redux";

export const MyCompletedShoots = () => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const [currentPage, setCurrentPage] = useState(0);
    const [shoots, setShoots] = useState(null);

    const getShoots = useCallback( async () => {
        callRemote(getAccessTokenSilently, GET, "/photographers/submitted-shoots")
            .then((shootsResponse) => {
                setShoots(shootsResponse);
            }).catch(e => {
            dispatch(determineIfTokenError(e));
        });
      }, [dispatch, getAccessTokenSilently]);

    useEffect(() => {
        getShoots();
    }, [getShoots]);

    return (
        <div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Submitted Date</th>
                        <th>Car</th>
                        <th>Shoot Date</th>
                        <th>Paid Date</th>
                        <th>Paid Amount</th>
                    </tr>
                </thead>
                <tbody>{ shoots != null && shoots.map(shoot => (
                            <tr key={shoot.id}>
                                <td>{dayjs(shoot.submittedDate).format('DD/MM/YYYY h:mm a')}</td>
                                <td>{shoot.car}</td>
                                <td>{shoot.shootDate ? dayjs(shoot.shootDate).format('MM/DD/YYYY h:mm a') : null}</td>
                                <td>{shoot.paidDate ? dayjs(shoot.paidDate).format('MM/DD/YYYY h:mm a') : null}</td>
                                <td>{shoot.paidAmount ? formatCurrency(shoot.paidAmount) : null}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>

            <PaginationControls elements={shoots} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
    );
};

export default MyCompletedShoots;
