import React, {useCallback, useEffect, useState} from "react";
import {Button, ButtonGroup, Table, UncontrolledTooltip} from "reactstrap";
import dayjs from 'dayjs'
import {useAuth0} from "@auth0/auth0-react";
import {callRemote, GET} from "../../../service/RemoteService";
import {determineIfTokenError} from "../../../store/AuthThunks";
import {useDispatch} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import JobCancelModal from "../modals/JobCancelModal";
import JobSubmitModal from "../modals/JobSubmitModal";
import {Link} from "react-router-dom";
import YoutubeUploadDialog from "../modals/YoutubeUploadDialog";

export const MyjobsTable = (props) => {
    let dispatch = useDispatch();
    const { user, getAccessTokenSilently } = useAuth0();

    const [jobs, setjobs] = useState(null);
    const [submitModalState, setSubmitModalState] = useState({ isOpen: false });
    const [cancelModalState, setCancelModalState] = useState({ isOpen: false });
    const [youtubeDialogState, setYoutubeDialogState] = useState({isOpen:false})

    const getJobs = useCallback(async () => {
        callRemote(getAccessTokenSilently, GET, "/jobs/my-jobs")
            .then((jobsResponse) => {
                setjobs(jobsResponse);
            }).catch(e => {
                dispatch(determineIfTokenError(e));
            });
    }, [dispatch, getAccessTokenSilently]);

    useEffect(() => {
        getJobs();
    }, [getJobs]);

    const openSubmitModal = async (job) => {
        setSubmitModalState({ isOpen: true, job: job });
    }
    const closeSubmitModal = () => {
        setSubmitModalState({ isOpen: false });
    }

    const openCancelModal = async (job) => {
        setCancelModalState({ isOpen: true, job: job });
    }
    const closeCancelModal = () => {
        setCancelModalState({ isOpen: false });
    }

    const openYoutubeModal = async (job) => {
        setYoutubeDialogState({ isOpen: true, job: job });
    }
    const closeYoutubeModal = () => {
        setYoutubeDialogState({ isOpen: false });
    }

    const roles = user != null && user["http://exoticcartrader.com/role"];
    const isEditor = roles.includes('Editor');
    const isBlogger = roles.includes('Blogger');
    const isYoutuber = roles.includes('Youtuber');

    return (
        <div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Submitted Date</th>
                        <th>Car</th>
                        <th>VIN</th>
                        <th>Type</th>
                        {isEditor ? (<th>URL String</th>) : null}
                        <th>Actions</th>
                        <th>Links</th>
                    </tr>
                </thead>
                <tbody>{jobs != null && jobs.jobs.map(job => (
                    <tr key={job.id}>
                        <td>{dayjs(job.photosSubmittedDate).format('MM/DD/YYYY h:mm a')}</td>
                        <td>{job.car}</td>
                        <td>{job.vin}</td>
                        <td>{job.jobType}</td>
                        {isEditor ? (<td>{job.urlString}</td>) : null}
                        <td>
                            <ButtonGroup>
                                <Button id={'submitBtn'} color="success"
                                  onClick={() => (isYoutuber && job.jobType === 'Youtuber') ? openYoutubeModal(job) : openSubmitModal(job)}
                                >
                                    <FontAwesomeIcon icon="check" />
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="submitBtn"
                                        trigger="hover"
                                    >
                                        Submit
                                    </UncontrolledTooltip>
                                </Button>
                                <Button onClick={() => openCancelModal(job)} id={'cancelBtn'} color="danger">
                                    <FontAwesomeIcon icon="ban" />
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="cancelBtn"
                                        trigger="hover"
                                    >
                                        Cancel
                                    </UncontrolledTooltip>
                                </Button>
                            </ButtonGroup>
                        </td>
                        <td>
                            <a href={job.photosLink} target="_blank" rel="noopener noreferrer">Photographer Photos</a> <br />
                            {((isEditor && job.jobType === 'Editor')) && (
                              <><a href="https://my.sirv.com/#/browse/For%2520Sale%2520PICTURES" target="_blank" rel="noopener noreferrer">Upload Edited Photos</a><br /></>)}
                            {(isBlogger && job.jobType === 'Blogger') && (
                              <><a href={job.instructionLink} target="_blank" rel="noopener noreferrer">Listing Instructions</a><br /></>)}
                            {(isEditor && job.jobType === 'Editor') && (
                                <><Link to={`/upload-photos/${job.id}/${job.car}`}>Upload Edited Photos to S3</Link><br /></>)}
                        </td>
                    </tr>
                ))
                }
                </tbody>
            </Table>

            <JobSubmitModal
                photographerId={user.sub}
                job={submitModalState.job}
                isOpen={submitModalState.isOpen}
                getJobs={getJobs}
                closeModal={closeSubmitModal}
                setModalState={setSubmitModalState}
            />
            <JobCancelModal
                job={cancelModalState.job}
                isOpen={cancelModalState.isOpen}
                getJobs={getJobs}
                closeModal={closeCancelModal}
                setModalState={setCancelModalState}
            />
            <YoutubeUploadDialog
              isOpen={youtubeDialogState.isOpen}
              closeModal={closeYoutubeModal}
              job={youtubeDialogState.job}
              getJobs={getJobs}
            />
        </div>
    );
};

export default MyjobsTable;
