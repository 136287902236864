import {Alert, Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React, {useState} from "react";
import {callRemote, POST} from "../../../service/RemoteService";
import {shootsActions} from "../../../store/storeIndex";
import {displayAlert} from "../../../store/AlertSlice";
import {determineIfTokenError} from "../../../store/AuthThunks";
import {useDispatch} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";


const YoutubeUploadDialog = (props) => {
  let {isOpen, closeModal, job, getJobs} = props;
  let dispatch = useDispatch();
  const { getAccessTokenSilently} = useAuth0();

  const [videoUrl, setVideoUrl] = useState('')

  const submit = async () => {
    if (validateUrl(videoUrl)) {
      callRemote(getAccessTokenSilently, POST, "/jobs/" + job.id + "/submit", {walkaroundVideoUrl:videoUrl})
        .then(() => {
          dispatch(shootsActions.incrementActionsTaken());
          dispatch(displayAlert(true, "Job Submitted"));
        }).catch(e => {
          dispatch(determineIfTokenError(e));
        }).finally(() => {
          getJobs();
          closeModal();
        })
    }
  }

  const handleInputChange = (val) => {
    setVideoUrl(val)
  }

  function validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Mark Walkaround Video As Submitted</ModalHeader>
        <ModalBody>
          <Alert color="info">
            Copy and Paste the Title and the Description when making the Youtube video.
            After the video is created, copy and paste the URL for the video into the input below, and submit.
          </Alert>
          <Form>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input type="textarea" name="title" id="title" readOnly={true}
                value={job?.car + ' Walk-around Video'}
              />
            </FormGroup>

            <FormGroup>
              <Label for="description">description</Label>
              <Input type="textarea" name="description" id="description" readOnly={true} rows={10}
                     value={'#ExoticCarTrader #ExoticCars\nIn this video,\n\n' +
                     'Listing Link: https://www.exoticcartrader.com/listing/' + job?.lotNumber + "\n\n" +
                     job?.overviewBlog + "\n\n" +
                     "Timestamps\n" +
                     "0:00 Introduction\n" +
                     "\n" +
                     "👇 Subscribe to Our Channel! 👇\n" +
                     "https://bit.ly/2U1s6Be\n" +
                     "\n" +
                     "Exotic Car Trader is United States' no. 1 automotive marketplace and car-buying resource. We help more exotic car buyers and enthusiasts at Exotic Car Trader than anybody else to compare vehicles, find deals and connect with others who share the same interests in fast, exotic, and luxurious cars!\n" +
                     "\n" +
                     "Our YouTube Channel brings car-buyers up close and personal with all the leading car companies and vehicles through segments like walkarounds and showcasing videos. By helping more buyers than anyone else, Exotic Car Trader is your number one companion to bring you up to speed on the latest vehicles in the automotive industry, in and out of the United States!\n" +
                     "\n" +
                     "Check Out our Socials!\n" +
                     "Instagram - https://www.instagram.com/exoticcartrader/#\n" +
                     "Facebook - https://www.facebook.com/exoticcartrader\n" +
                     "Website - https://www.exoticcartrader.com/\n" +
                     "\n" +
                     "Check Out Our Listings on Car Guru, eBay, and more!\n" +
                     "For any inquiries on any of the cars on our page, call us at 954.368.3899.\n" +
                     "\n" +
                     "Subscribe to Our Channel! - https://bit.ly/2U1s6Be\n" +
                     "👍 Like this video? Like it & Share it with friends!\n" +
                     "🔔 Turn on post Notifications to get all my latest updates\n" +
                     "📥 Comment below if you have any questions or comments!\n\n#ExoticCarTrader #ExoticCars"
                     }
              />
            </FormGroup>

            <FormGroup>
              <Label for="video">Youtube Video URL</Label>
              <Input type="text" name="video" id="video" value={videoUrl}
                     placeholder="ex: https://www.youtube.com/watch?v=Ih_SCRqqevo"
                     onChange={(e) => handleInputChange(e.target.value)}
                     invalid={!validateUrl(videoUrl)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => submit()} color="primary"
                  disabled={!validateUrl(videoUrl)}>
            Submit
          </Button>
          <Button onClick={() => closeModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default YoutubeUploadDialog;