import React, {useCallback, useEffect, useState} from "react";
import {Button, Table} from "reactstrap";
import dayjs from 'dayjs'
import PaginationControls from "./PaginationControls";
import UndoCancelModal from "../modals/UndoCancelModal";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch} from "react-redux";
import {callRemote, GET} from "../../../service/RemoteService";
import {determineIfTokenError} from "../../../store/AuthThunks";

export const CancelledTable = () => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const [currentPage, setCurrentPage] = useState(0);
    const [cancelModalState, setCancelModalState] = useState({ isOpen: false });
    const [shoots, setShoots] = useState();

    const getShoots = useCallback(async () => {
        callRemote(getAccessTokenSilently, GET, "/shoots/?status=cancelled&pageNumber=" + currentPage)
        .then((shootsResponse) => {
            setShoots(shootsResponse);
        }).catch(e => {
            dispatch(determineIfTokenError(e));
        });
    }, [currentPage, dispatch, getAccessTokenSilently])

    useEffect(() => {
        getShoots();
    }, [getShoots, cancelModalState]);

    const openCancelModal = async (shoot) => {
        setCancelModalState({ isOpen: true, shoot: shoot });
    }
    const closeCancelModal = () => {
        setCancelModalState({ isOpen: false });
    }

    return (
        <div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Canelled Date</th>
                        <th>Vehicle</th>
                        <th>Customer</th>
                        <th>Photographer</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>{shoots != null && shoots.shoots.map(shoot => (
                    <tr key={shoot.id}>
                        <td>{dayjs(shoot.cancelledDate).format('DD/MM/YYYY h:mm a')}</td>
                        <td>{shoot.car}</td>
                        <td>{shoot.customerName}</td>
                        <td>{shoot.photographerName}</td>
                        <td><Button onClick={() => openCancelModal(shoot)} color="primary">Reactivate</Button></td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <PaginationControls elements={shoots} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
            <UndoCancelModal
                shoot={cancelModalState.shoot}
                isOpen={cancelModalState.isOpen}
                getShoots={getShoots}
                closeModal={closeCancelModal}
                setModalState={setCancelModalState}
            />
        </div>
    );
};

export default CancelledTable;
