import React, {useCallback, useEffect, useState} from "react";
import {Alert, Button, ButtonGroup, Table, UncontrolledTooltip} from "reactstrap";
import dayjs from 'dayjs'
import ScheduleModal from "../modals/ScheduleModal";
import SubmitModal from "../modals/SubmitModal";
import PhotographerCancelModal from "../modals/PhotographerCancelModal";
import PaginationControls from "./PaginationControls";
import {useAuth0} from "@auth0/auth0-react";
import {callRemote, GET} from "../../../service/RemoteService";
import {determineIfTokenError} from "../../../store/AuthThunks";
import {useDispatch} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const MyShootsTable = (props) => {
    let dispatch = useDispatch();
    const { user, getAccessTokenSilently } = useAuth0();
    const [currentPage, setCurrentPage] = useState(0);
    const [shoots, setShoots] = useState(null);
    const [scheduleModalState, setScheduleModalState] = useState({ isOpen: false });
    const [submitModalState, setSubmitModalState] = useState({ isOpen: false });
    const [cancelModalState, setCancelModalState] = useState({ isOpen: false });

    const getShoots = useCallback(async () => {
        callRemote(getAccessTokenSilently, GET, "/photographers/shoots")
            .then((shootsResponse) => {
                setShoots(shootsResponse);
            }).catch(e => {
                dispatch(determineIfTokenError(e));
            });
    }, [dispatch, getAccessTokenSilently]);

    useEffect(() => {
        getShoots();
    }, [getShoots]);

    const openSubmitModal = async (shoot) => {
        setSubmitModalState({ isOpen: true, shoot: shoot });
    }
    const closeSubmitModal = () => {
        setSubmitModalState({ isOpen: false });
    }

    const openScheduleModal = async (shoot) => {
        setScheduleModalState({ isOpen: true, shoot: shoot });
    }
    const closeScheduleModal = () => {
        setScheduleModalState({ isOpen: false });
    }

    const openCancelModal = async (shoot) => {
        setCancelModalState({ isOpen: true, shoot: shoot });
    }
    const closeCancelModal = () => {
        setCancelModalState({ isOpen: false });
    }

    return (
        <div>
            <Alert>Once you have uploaded all the photos and videos, you must come back to this page and press the green submit button to be paid </Alert>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Signing Date</th>
                        <th>Car</th>
                        <th>Customer</th>
                        <th>Shoot Date</th>
                        <th>Actions</th>
                        <th>Links</th>
                    </tr>
                </thead>
                <tbody>{shoots != null && shoots.map(shoot => (
                    <tr key={shoot.id}>
                        <td>{dayjs(shoot.signingDate).format('MM/DD/YYYY h:mm a')}</td>
                        <td>{shoot.car}</td>
                        <td>{shoot.customerName}</td>
                        <td>{shoot.shootDate ? dayjs(shoot.shootDate).format('MM/DD/YYYY h:mm a') : null}</td>
                        <td>
                            <ButtonGroup>
                                <Button id={'scheduleBtn'} onClick={() => openScheduleModal(shoot)} color="primary">
                                    <FontAwesomeIcon icon="calendar-day" />
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="scheduleBtn"
                                        trigger="hover"
                                    >
                                        Schedule
                                    </UncontrolledTooltip>
                                </Button>
                                <Button id={'submitBtn'} onClick={() => openSubmitModal(shoot)} color="success">
                                    <FontAwesomeIcon icon="check" />
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="submitBtn"
                                        trigger="hover"
                                    >
                                        Submit
                                    </UncontrolledTooltip>
                                </Button>
                                <Button id={'cancelBtn'} onClick={() => openCancelModal(shoot)} color="danger">
                                    <FontAwesomeIcon icon="ban" />
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="cancelBtn"
                                        trigger="hover"
                                    >
                                        Cancel
                                    </UncontrolledTooltip>
                                </Button>
                            </ButtonGroup>
                        </td>
                        <td>
                            <a href={shoot.instructionLink} target="_blank" rel="noopener noreferrer">Instructions</a> <br />
                            <a href={shoot.submissionLink} target="_blank" rel="noopener noreferrer">Upload Photos</a>
                        </td>
                    </tr>
                ))
                }
                </tbody>
            </Table>

            <PaginationControls elements={shoots} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            <ScheduleModal
                photographerId={user.sub}
                shoot={scheduleModalState.shoot}
                isOpen={scheduleModalState.isOpen}
                getShoots={getShoots}
                closeModal={closeScheduleModal}
                setModalState={setScheduleModalState}
            />
            <SubmitModal
                photographerId={user.sub}
                shoot={submitModalState.shoot}
                isOpen={submitModalState.isOpen}
                getShoots={getShoots}
                closeModal={closeSubmitModal}
                setModalState={setSubmitModalState}
            />
            <PhotographerCancelModal
                shoot={cancelModalState.shoot}
                isOpen={cancelModalState.isOpen}
                getShoots={getShoots}
                closeModal={closeCancelModal}
                setModalState={setCancelModalState}
            />
        </div>
    );
};

export default MyShootsTable;
