import React, {useCallback, useEffect, useState} from "react";
import {Button, ButtonGroup, Table, UncontrolledTooltip} from "reactstrap";
import dayjs from 'dayjs'
import PaginationControls from "./PaginationControls";
import CancelModal from "../modals/CancelModal";
import AssignModal from "../modals/AssignModal";
import ScheduleModal from "../modals/ScheduleModal";
import {useAuth0} from "@auth0/auth0-react";
import {callRemote, GET} from "../../../service/RemoteService";
import {determineIfTokenError} from "../../../store/AuthThunks";
import {useDispatch} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ActiveTable = (props) => {
    let dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const [cancelModalState, setCancelModalState] = useState({ isOpen: false });
    const [assignModalState, setAssignModalState] = useState({ isOpen: false });
    const [scheduleModalState, setScheduleModalState] = useState({ isOpen: false });
    const [shoots, setShoots] = useState();
    const { getAccessTokenSilently } = useAuth0();

    const getShoots = useCallback(async () => {
        callRemote(getAccessTokenSilently, GET, "/shoots/?status=active&pageNumber=" + currentPage)
        .then((shootsResponse) => {
            setShoots(shootsResponse);
        }).catch(e => {
            dispatch(determineIfTokenError(e));
        });
    }, [currentPage, getAccessTokenSilently, dispatch]);

    useEffect(() => {
        getShoots();
    }, [getShoots]);

    const openCancelModal = async (shoot) => {
        setCancelModalState({ isOpen: true, shoot: shoot });
    }
    const closeCancelModal = () => {
        setCancelModalState({ isOpen: false });
    }

    const openAssignModal = async (shoot) => {
        setAssignModalState({ isOpen: true, shoot: shoot });
    }
    const closeAssignModal = () => {
        setAssignModalState({ isOpen: false });
    }
    const openScheduleModal = async (shoot) => {
        setScheduleModalState({ isOpen: true, shoot: shoot });
    }
    const closeScheduleModal = () => {
        setScheduleModalState({ isOpen: false });
    }

    return (
        <div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Signing Date</th>
                        <th>Vehicle</th>
                        <th>VIN</th>
                        <th>Customer</th>
                        <th>Photographer</th>
                        <th>Shoot Date</th>
                        <th>Actions</th>
                        <th>Links</th>
                    </tr>
                </thead>
                <tbody>{shoots != null && shoots.shoots.map(shoot => (
                    <tr key={shoot.id}>
                        <td>{dayjs(shoot.signingDate).format('MM/DD/YYYY h:mm a')}</td>
                        <td>{shoot.car}</td>
                        <td>{shoot.vin}</td>
                        <td>{shoot.customerName}</td>
                        <td>{shoot.photographerName}</td>
                        <td>{shoot.shootDate ? dayjs(shoot.shootDate).format('MM/DD/YYYY h:mm a') : null}</td>
                        <td>
                            <ButtonGroup>
                                <Button id={'reassignBtn'} onClick={() => openAssignModal(shoot)} color="primary">
                                    <FontAwesomeIcon icon="redo" />
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="reassignBtn"
                                        trigger="hover"
                                    >
                                        Re-Assign
                                    </UncontrolledTooltip>
                                </Button>
                                <Button id={'schedBtn'} onClick={() => openScheduleModal(shoot)} color="primary">
                                    <FontAwesomeIcon icon="calendar-day" />
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="schedBtn"
                                        trigger="hover"
                                    >
                                        Schedule
                                    </UncontrolledTooltip>
                                </Button>
                                <Button id={'cancelBtn'} onClick={() => openCancelModal(shoot)} color="danger">
                                    <FontAwesomeIcon icon="ban" />
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="cancelBtn"
                                        trigger="hover"
                                    >
                                        Cancel
                                    </UncontrolledTooltip>
                                </Button>
                            </ButtonGroup>
                        </td>
                        <td><a href={shoot.instructionLink} target="_blank" rel="noopener noreferrer">Instruction Sheet</a></td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <PaginationControls elements={shoots} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            <CancelModal
                shoot={cancelModalState.shoot}
                isOpen={cancelModalState.isOpen}
                getShoots={getShoots}
                closeModal={closeCancelModal}
                setModalState={setCancelModalState}
            />
            <AssignModal
                photographers={props.photographers}
                isOpen={assignModalState.isOpen}
                getShoots={() => getShoots(currentPage)}
                closeModal={closeAssignModal}
                setModalState={setAssignModalState}
                shoot={assignModalState.shoot}
            />
            <ScheduleModal
                shoot={scheduleModalState.shoot}
                isOpen={scheduleModalState.isOpen}
                getShoots={getShoots}
                closeModal={closeScheduleModal}
                setModalState={setScheduleModalState}
            />
        </div>
    );
};

export default ActiveTable;
