import React from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { callRemote, PUT } from "../../../service/RemoteService";
import { determineIfTokenError } from "../../../store/AuthThunks";
import { withRouter } from "../../../utils/withRouter"

export const GrabModal = (props) => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently, user } = useAuth0();
    
    const assign = async () => {
        const job = props.job;
        job.creatorId = user.sub;
        try {
        await callRemote(getAccessTokenSilently, PUT, "/jobs/" + job.id, { ...job });
        props.history('/my-jobs');

        } catch (e) {
            dispatch(determineIfTokenError(e));
        } finally {
            props.closeModal();
        }
    }


    return (
        <Modal isOpen={props.isOpen} toggle={props.closeModal} size="lg">
            <ModalHeader toggle={props.closeModal}>Grab Job</ModalHeader>
            <ModalBody>
                <h4>Do you want to claim this job? You will not be able to grab any others until this is completed.</h4>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => assign()} color="primary">Grab</Button>
                <Button onClick={() => props.setModalState({ isOpen: false })}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default withRouter(GrabModal);
